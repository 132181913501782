import Navigation from "./navigation";
import Snack from "./components/snack"

function App() {
  return (
    <>
      {/* <Snack> */}

        <Navigation />
      {/* </Snack> */}
    </>
  );
}

export default App;
