import React from 'react'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import "../../font.css/index.css"


const Index = () => {
  return (
   <>
    <ResetPasswordForm/>
   </>
  )
}

export default Index
