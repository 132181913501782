import React from 'react'
import ForgotPasswordForm from '../../components/ForgotPasswordForm'
import "../../font.css/index.css"


const Index = () => {
  return (
   <>
    <ForgotPasswordForm/>
   </>
  )
}

export default Index
